import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

const FinishedProductForm = ({ handleBackClick }) => {
  const [name, setName] = useState('');
  const [productionDate, setProductionDate] = useState(''); // New "date produced" state
  const [expirationDate, setExpirationDate] = useState(''); // Renamed from dateProduced -> expirationDate
  const [itemNumber, setItemNumber] = useState('');
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({}); // Reset error state

    const newErrors = {};
    const today = new Date().toISOString().split('T')[0];

    // Validate name
    if (!name) {
      newErrors.name = 'Product name is required.';
    }

    // Validate production date
    if (!productionDate) {
      newErrors.productionDate = 'Production date is required.';
    } else if (productionDate < today) {
      newErrors.productionDate = 'Production date cannot be in the past.';
    }

    // Validate expiration date
    if (!expirationDate) {
      newErrors.expirationDate = 'Expiration date is required.';
    } else if (expirationDate < productionDate) {
      newErrors.expirationDate = 'Expiration date cannot be before production date.';
    }

    // Validate selected materials
    if (selectedMaterials.length === 0) {
      newErrors.selectedMaterials = 'At least one raw material or finished product is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Prepare data for POST
    const rawMaterialIds = selectedMaterials.map((material) => material.id);
    const finishedProductData = {
      name,
      // Use the new state variables
      dateProduced: productionDate,
      dateExpires: expirationDate, // or "expirationDate" if your backend uses that name
      itemNumber,
    };

    const dto = {
      finishedProduct: finishedProductData,
      rawMaterialIds,
    };

    try {
      const response = await fetch(
          'https://trackingnumbers.azurewebsites.net/api/finishedproducts',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(dto),
          }
      );

      const responseData = await response.json();
      if (response.ok) {
        handleBackClick();
        navigate('/');
      } else {
        setErrors({
          submit: responseData.message || 'Error submitting finished product.',
        });
      }
    } catch (error) {
      setErrors({ submit: 'Error submitting finished product.' });
    }
  };

  const handleBack = () => {
    handleBackClick();
    navigate('/');
  };

  // AsyncSelect loading logic
  const loadOptions = debounce((inputValue, callback) => {
    if (!inputValue || inputValue.length < 3) {
      callback([]);
      return;
    }

    const fetchOptions = async () => {
      try {
        const [rawMaterialResponse, finishedProductResponse] = await Promise.all([
          fetch(
              `https://trackingnumbers.azurewebsites.net/api/rawmaterials/search?query=${inputValue}`,
              { credentials: 'include' }
          ),
          fetch(
              `https://trackingnumbers.azurewebsites.net/api/finishedproducts/search?query=${inputValue}`,
              { credentials: 'include' }
          ),
        ]);

        const [rawMaterialData, finishedProductData] = await Promise.all([
          rawMaterialResponse.json(),
          finishedProductResponse.json(),
        ]);

        const rawMaterialsArray = rawMaterialData.$values ?? [];
        const finishedProductsArray = finishedProductData.$values ?? [];

        const options = [...rawMaterialsArray, ...finishedProductsArray]
            .sort(
                (a, b) =>
                    new Date(b.dateReceived || b.dateProduced) -
                    new Date(a.dateReceived || a.dateProduced)
            )
            .map((material) => ({
              id: material.id,
              value: material.batchNumber,
              label: `${material.name} (${material.batchNumber})`,
              batchNumber: material.batchNumber,
            }));

        callback(options);
      } catch (error) {
        callback([]);
      }
    };

    fetchOptions();
  }, 300);

  return (
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <FaArrowLeft
                size={24}
                onClick={handleBack}
                style={{ cursor: 'pointer' }}
            />
            <Card.Title>Færdigt produkt</Card.Title>
            <div style={{ width: '24px' }}></div> {/* Placeholder for alignment */}
          </div>

          {errors.submit && <Alert variant="danger">{errors.submit}</Alert>}

          <Form onSubmit={handleSubmit} noValidate>
            {/* PRODUCT NAME */}
            <Form.Group controlId="name">
              <Form.Label>Produkt navn</Form.Label>
              <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!errors.name}
              />
              {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* PRODUCTION DATE */}
            <Form.Group controlId="productionDate" className="mt-3">
              <Form.Label>Produktionsdato</Form.Label>
              <Form.Control
                  type="date"
                  value={productionDate}
                  onChange={(e) => setProductionDate(e.target.value)}
                  isInvalid={!!errors.productionDate}
              />
              {errors.productionDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.productionDate}
                  </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* EXPIRATION DATE (renamed from dateProduced) */}
            <Form.Group controlId="expirationDate" className="mt-3">
              <Form.Label>Udløbsdato</Form.Label>
              <Form.Control
                  type="date"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  isInvalid={!!errors.expirationDate}
              />
              {errors.expirationDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.expirationDate}
                  </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* ITEM NUMBER */}
            <Form.Group controlId="itemNumber" className="mt-3">
              <Form.Label>Varenummer (ikke påkrævet)</Form.Label>
              <Form.Control
                  type="text"
                  value={itemNumber}
                  onChange={(e) => setItemNumber(e.target.value)}
              />
            </Form.Group>

            {/* MATERIALS (AsyncSelect) */}
            <Form.Group controlId="rawMaterials" className="mt-3">
              <Form.Label>Materialer brugt:</Form.Label>
              <AsyncSelect
                  isMulti
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions
                  value={selectedMaterials}
                  onChange={setSelectedMaterials}
                  className={`mt-2 ${errors.selectedMaterials ? 'is-invalid' : ''}`}
                  placeholder="Indtast navn eller batch nummer"
              />
              {errors.selectedMaterials && (
                  <div className="invalid-feedback d-block">
                    {errors.selectedMaterials}
                  </div>
              )}
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Opret vare
            </Button>
          </Form>
        </Card.Body>
      </Card>
  );
};

export default FinishedProductForm;
