import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';

const RawMaterialForm = ({ handleBackClick }) => {
  const [name, setName] = useState('');
  const [batchNumber, setBatchNumber] = useState('');
  const [dateProduced, setDateProduced] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [itemNumber, setItemNumber] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({}); // Reset error state

    // Validate form fields
    const newErrors = {};
    const today = new Date().toISOString().split('T')[0];

    if (!name) newErrors.name = 'Material name is required.';
    if (!batchNumber) newErrors.batchNumber = 'Batch number is required.';

    if (!expirationDate) {
      newErrors.dateReceived = 'Date received is required.';
    } else if (expirationDate < today) {
      newErrors.dateReceived = 'Date received cannot be in the past.';
    }

    // Validate dateProduced (optional or required - shown here as required)
    if (!expirationDate) {
      newErrors.dateProduced = 'Date produced is required.';
    } else if (expirationDate < today) {
      newErrors.dateProduced = 'Date produced cannot be in the past.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Prepare the data to be sent to the backend
    const rawData = {
      name,
      batchNumber,
      itemNumber,
      dateProduced: dateProduced,
      expirationDate: expirationDate,
    };

    try {                                           
      const response = await fetch('https://trackingnumbers.azurewebsites.net/api/rawmaterials', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(rawData),
      });


      const responseData = await response.json();
      if (response.ok) {
        handleBackClick();
        navigate('/');
      } else {
        setErrors({ submit: responseData.message || 'Error submitting raw material.' });
      }
    } catch (error) {
      setErrors({ submit: 'Error submitting raw material. ' + error });
    }
  };

  const handleBack = () => {
    handleBackClick();
    navigate('/');
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  return (
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <FaArrowLeft
                size={24}
                onClick={handleBack}
                style={{ cursor: 'pointer' }}
            />
            <Card.Title>Opret rå-vare</Card.Title>
            <div style={{ width: '24px' }}></div> {/* Placeholder for alignment */}
          </div>
          {errors.submit && <Alert variant="danger">{errors.submit}</Alert>}
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Group controlId="name">
              <Form.Label>Materiale navn</Form.Label>
              <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!errors.name}
              />
              {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="batchNumber" className="mt-3">
              <Form.Label>Batch nummer</Form.Label>
              <Form.Control
                  type="text"
                  value={batchNumber}
                  onChange={(e) => setBatchNumber(e.target.value)}
                  isInvalid={!!errors.batchNumber}
              />
              {errors.batchNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.batchNumber}
                  </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="dateReceived" className="mt-3">
              <Form.Label>Udløbsdato</Form.Label>
              <Form.Control
                  type="date"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  isInvalid={!!errors.dateReceived}
              />
              {errors.expirationDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.expirationDate}
                  </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* New "date produced" field */}
            <Form.Group controlId="dateProduced" className="mt-3">
              <Form.Label>Dato produceret</Form.Label>
              <Form.Control
                  type="date"
                  value={dateProduced}
                  onChange={(e) => setDateProduced(e.target.value)}
                  isInvalid={!!errors.dateProduced}
              />
              {errors.dateProduced && (
                  <Form.Control.Feedback type="invalid">
                    {errors.dateProduced}
                  </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="itemNumber" className="mt-3">
              <Form.Label>Varenummer (ikke påkrævet)</Form.Label>
              <Form.Control
                  type="text"
                  value={itemNumber}
                  onChange={(e) => setItemNumber(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Opret rå-vare
            </Button>
          </Form>
        </Card.Body>
      </Card>
  );
};

export default RawMaterialForm;
